.detail {
    margin-left: 1.5em;
}

.detail-heading {
    color: #ABABAB;
    font-weight: 600;
}

.detail-sub-heading {
    color: #B1B1B1;
}
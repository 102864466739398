.popular-one-issue-item {
  display: grid;
  grid-template-columns: auto;
}

.popular-one-issue-image {
  height: 200px;
  width: 248px;
  border-radius: 11px;
}

.popular-one-issue-title {
  margin-top: 23px;
  max-width: 248px;
}

.popular-one-issue-title-text {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.one-control-icon-style {
  color: #7f7f7f;
  margin-right: 5px;
  cursor: pointer;
}

.one-control-icon-style:hover {
  color: #2b2b2b;
}

.popular-one-issue-one-control {
  display: flex;
  gap: 1em;
}

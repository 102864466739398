.my-sidebar-style {
    background-color: #F3F3F3 !important;
    max-width: 250px !important;
    width: 250px !important;
}

.menu-logo-style{
    height: 150px ;
}

.my-sidebar-menu-style {
    background-color: transparent !important;
    position: relative !important;
}

.my-sidebar-menu-item {
    background-color: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px !important;
    margin: 15px 0px !important;
}

.my-sidebar-menu-style>.ant-menu-item {
    color: #999999 !important;
}

.my-sidebar-menu-style>.ant-menu-item-selected {
    color: black !important;
}

.my-sidebar-menu-style>.ant-menu-item-active {
    color: rgb(59, 59, 59) !important;
}

.my-menu-item-spacer{
    height: 30px;
}
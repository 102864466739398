.voting-results-center {
  display: grid;
  row-gap: 24px;
}

.voting-results-results {
  background: #f4f4f4;
  border-radius: 11px;
  padding: 56px 30px;
}

.voting-results-group {
  background: #f4f4f4;
  border-radius: 11px;
  padding: 45px;
}

.voting-results-winner {
  background: #f4f4f4;
  border-radius: 11px;
  text-align: center;
  padding: 20px;
}

.voting-results-results-detail {
  background: #f4f4f4;
  border-radius: 11px;
  display: grid;
  grid-template-rows: auto auto;
  padding: 20px;
}

.voting-results-bar {
  border-radius: 0px 12px 12px 0px;
  height: 36px;
  text-align: end;
}

.voting-results-bar-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.voting-results-bar-item-text {
  width: 10em;
  flex-shrink: 0;
  padding: 8px;
}

.detail {
  margin-left: unset;
}

@media screen and (max-width: 1224px) {
  .voting-results-main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;
  }
}

@media screen and (min-width: 1224px) {
  .voting-results-main {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 42px;
    margin-top: 48px;
  }
}

.voting-detail-main {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 35px;
  margin-left: 20px;
  margin-top: 56px;
  margin-bottom: 56px;
  height: 100%;
}

.voting-detail-center {
  display: grid;
  row-gap: 35px;
}

.voting-detail-side {
  display: grid;
}

.voting-detail-image {
  border-radius: 11px;
  width: 100%;
  height: 18em;
}

.voting-detail-block {
  background: #f4f4f4;
  border-radius: 11px;
  padding: 48px;
}

.voting-detail-info {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.voting-detail-opinion-box {
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  row-gap: 26px;
}

.voting-detail-buttons {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
}

.voting-detail-icon-color {
  color: #373737 !important;
}

.voting-detail-text {
  color: #7f7f7f;
  padding: 8px;
}

.voting-detail-edit-button {
  display: flex;
  gap: 10px;
}

.voting-detail-input {
  background: #f4f4f4 !important;
  border-radius: 3px !important;
  height: 42px;
}

.voting-detail-edit-button > .ant-btn-circle {
  border-radius: 50% !important;
}

.voting-detail-image-container {
  position: relative;
}

.voting-detail-top-right-button {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

.voting-detail-top-right-button button {
  border-radius: 32px !important;
}

.voting-detail-top-buttons {
  display: flex;
  gap: 14px;
}

.voting-detail-top-buttons button {
  height: 54px !important;
  width: 12em;
}

.post-voting-attachments {
  background: #f4f4f4;
  border-radius: 7px;
  width: 100%;
  padding: 28px;
  text-align: center;
}

.post-voting-green {
  color: #00ba34;
}

.post-voting-red {
  color: #ff3a3a;
}

.post-voting-attachment {
  display: grid;
  grid-template-columns: auto auto;
}

.post-voting-delete {
  cursor: pointer;
}

.voting-detail-profile {
  background: #fcfcfc;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 1em;
}

.voting-detail-profile-pic {
  border-radius: 50%;
  width: 54px;
  height: 54px;
}

.voting-detail-message-block {
  position: relative;
  display: flex;
  gap: 10px;
}

.voting-detail-message-profile-pic {
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.voting-detail-message-text{
  padding: 11px;
  border-radius: 15px;
}

.voting-detail-send-input {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.voting-detail-send-input > span {
  border-radius: "6px" !important;
}

.voting-detail-send-input > input {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.ant-input-affix-wrapper:focus {
  border-color: #FFFFFF !important;
}

.voting-detail-white-text {
  color: #FFFFFF !important;
}

.voting-detail-date {
  display: flex;
  flex-direction:column;
  margin-bottom: 20px;
}
@media screen and (max-width: 1224px) {
  .post-suggestion-main {
    display: grid;
    margin-top: 50px;
  }
  .post-suggestion-button {
    display: grid;
    align-items: center;
  }
}

@media screen and (min-width: 1224px) {
  .post-suggestion-main {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 50px;
    column-gap: 50px;
  }
  .post-suggestion-button {
    display: grid;
    align-items: center;
    width: 220px;
  }
  .post-suggestion-side {
    display: flex;
    row-gap: 28px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.post-suggestion-center {
  display: grid;
  grid-template-rows: auto auto auto;
}

.post-suggestion-upload {
  padding: 6em 2em 2em;
  background: #c4c4c4;
  border-radius: 6px;
  cursor: pointer;
}

.post-suggestion-input {
  background: #f4f4f4 !important;
  border-radius: 3px !important;
  height: 42px;
  width: 100%;
}

.post-suggestion-select .ant-select-selector {
  background: #f4f4f4 !important;
  border-radius: 3px !important;
  height: 42px;
  width: 100%;
}

.post-suggestion-attachments {
  background: #f4f4f4;
  border-radius: 7px;
  width: 100%;
  padding: 28px;
  text-align: center;
}

.post-suggestion-green {
  color: #00ba34;
}

.post-suggestion-red {
  color: #ff3a3a;
}

.post-suggestion-attachment {
  display: grid;
  grid-template-columns: auto auto;
}

.post-suggestion-delete {
  cursor: pointer;
}

.post-suggestion-fit-content {
  width: 100%;
}

.header-style {
  background-color: white !important;
  padding-left: 50px !important;
}

.header-user-info-container {
  text-align: right !important;
  width: 100%;
}

.breadcrumb-container {
  background-color: white;
  padding-left: 50px !important;
  height: 50px !important;
}

.breadcrumb-description-container {
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
}

@media screen and (max-width: 1224px) {
  .content-container {
    background-color: white !important;
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 50px;
  }

  .mobile-header {
    margin: 16px;
    display: flex;
    gap: 3em;
    justify-content: flex-end;
    align-items: center;
  }

  .header-user-avatar-container {
    border-radius: 10px !important;
  }

  .header-alert-count-container {
    display: flex;
  }
}

@media screen and (min-width: 1224px) {
  .content-container {
    background-color: white !important;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .header-user-avatar-container {
    border-radius: 10px !important;
    text-align: right;
    float: right !important;
  }

  .header-alert-count-container {
    float: right !important;
    width: 100px;
    text-align: center;
  }
}

.layout-container {
  margin-left: 250px;
  background: white !important;
}

.town-hall-main {
  margin-top: 56px;
  margin-bottom: 56px;
}

.town-hall-center {
  display: grid;
}

.town-hall-side {
  display: grid;
}

.town-hall-block {
  background: #f4f4f4;
  border-radius: 11px;
  padding: 48px;
}

.town-hall-icon-color {
  color: #373737 !important;
}

.town-hall-text {
  color: #7f7f7f;
  padding: 8px;
}

.town-hall-field .ant-input {
  background: #f4f4f4 !important;
  border-radius: 4px !important;
  height: 48px !important;
}

.town-hall-field .ant-select-clear {
  background: #f4f4f4 !important;
}

.town-hall-submit-btn {
  border-radius: 4px !important;
  height: 48px !important;
}

@media screen and (max-width: 1224px) {
  .town-hall-submit-section {
    display: flex;
    column-gap: 0.5em;
  }
  .town-hall-field .ant-select-selector {
    background: #f4f4f4 !important;
    border-radius: 4px !important;
    height: 48px !important;
    width: 17em !important;
  }
}

@media screen and (min-width: 1224px) {
  .town-hall-field .ant-select-selector {
    background: #f4f4f4 !important;
    border-radius: 4px !important;
    height: 48px !important;
    width: 100% !important;
  }
  .town-hall-submit-section {
    display: grid;
    grid-template-columns: 75% 25%;
    column-gap: 1em;
  }
}

.town-hall-suggestion-box {
  display: grid;
  background: #f4f4f4;
  padding: 40px;
  justify-items: center;
  border-radius: 11px;
}

.town-hall-margin-bottom-32 {
  margin-bottom: 32px;
}

.town-hall-link {
  color: #000;
}

.town-hall-suggestion-detail {
  background: #f4f4f4;
  padding: 20px;
  margin-top: -6px;
}

.town-hall-suggestion-image {
  border-radius: 11px;
  z-index: 1;
  height: 200px;
  width: 100%;
}

.town-hall-suggestion-button {
  width: 100%;
  margin-top: 14px;
}

.ant-select-single .ant-select-selector {
  align-items: center;
}

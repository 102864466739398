.vote-detail-image {
  border-radius: 11px;
  width: 100%;
}

.vote-detail-block {
  background: #f4f4f4;
  border-radius: 11px;
  padding: 48px;
}

.vote-detail-icon-color {
  color: #373737 !important;
}

.vote-detail-text {
  color: #7f7f7f;
  padding: 8px;
}

.vote-detail-center {
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
}

@media screen and (max-width: 1224px) {
  .vote-detail-main {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 56px;
  }
  .vote-detail-info {
    display: grid;
  }
  .vote-detail-opinion-box {
    display: grid;
    justify-items: center;
    row-gap: 26px;
  }
  .vote-detail-mandate-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2em;
  }
  .vote-detail-mandate-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .vote-detail-memoranda-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .vote-detail-mandate-outer-section {
    display: flex;
    flex-direction: row;
    border: solid 1px var(--adm-border-color);
    border-radius: 4px;
    padding: 8px;
    gap: 1em;
    align-items: center;
  }
  .vote-detail-memoranda-outer-section {
    display: flex;
    flex-direction: row;
    border: solid 1px var(--adm-border-color);
    border-radius: 4px;
    padding: 8px;
    gap: 1em;
    align-items: center;
  }
}

@media screen and (min-width: 1224px) {
  .vote-detail-main {
    display: flex;
    flex-direction: row;
    gap: 35px;
    margin-top: 56px;
  }
  .vote-detail-info {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .vote-detail-opinion-box {
    display: grid;
    grid-template-rows: auto auto auto;
    justify-items: center;
    row-gap: 26px;
  }
  .vote-detail-mandate-buttons {
    display: flex;
    gap: 2em;
  }
  .vote-detail-mandate-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 30%;
  }
  .vote-detail-memoranda-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 50%;
  }
}

.issue-detail-opinion-box {
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  row-gap: 26px;
}

.issue-detail-buttons {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
}

.issue-detail-icon-color {
  color: #373737 !important;
}

.issue-detail-text {
  color: #7f7f7f;
  padding: 10px;
  font-size: 24px;
}

@media screen and (max-width: 1224px) {
  .issue-detail-main {
    display: flex;
    flex-direction: column;
    column-gap: 35px;
    margin-top: 56px;
  }
  .issue-detail-info {
    display: grid;
  }
  .issue-detail-side {
    display: grid;
    row-gap: 26px;
    width: 100%;
  }
  .issue-detail-image {
    border-radius: 11px;
    width: 100%;
    height: 18em;
  }
  .issue-detail-center {
    display: grid;
    width: 100%;
  }

  .issue-detail-block {
    display: flex;
    background: #f4f4f4;
    border-radius: 11px;
    padding: 16px;
    flex-direction: column;
    gap: 2em;
    width: 100%;
  }
}

@media screen and (min-width: 1224px) {
  .issue-detail-main {
    display: grid;
    grid-template-columns: 75% 25%;
    column-gap: 35px;
    margin-left: 20px;
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .issue-detail-info {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .issue-detail-side {
    display: grid;
    row-gap: 26px;
    grid-template-rows: 18em 36em;
  }
  .issue-detail-image {
    border-radius: 11px;
    width: 100%;
    height: 18em;
  }
  .issue-detail-center {
    display: grid;
  }

  .issue-detail-block {
    display: flex;
    background: #f4f4f4;
    border-radius: 11px;
    padding: 48px;
    flex-direction: column;
    gap: 2em;
  }
}

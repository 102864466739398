:root {
  --adm-color-primary: #00ba34 !important;
  --antd-color-primary: #00ba34 !important;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

svg {
  vertical-align: unset !important;
}

.grey-text {
  color: #888888;
}

.green-text {
  color: #00ba34;
}

.white-text {
  color: white;
}

.button-color {
  background: #303030;
}

.ant-btn-primary {
  background: #303030 !important;
  border-color: unset !important;
  text-shadow: unset !important;
  box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
}

.ant-btn-primary:hover {
  color: #303030 !important;
  background: #fff !important;
  border-color: unset !important;
}

.ant-btn-inverted {
  background: #fff !important;
  border-color: unset !important;
  text-shadow: unset !important;
  box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
}

.ant-btn-inverted:hover {
  background: #303030 !important;
  color: #fff !important;
  border-color: unset !important;
}

.ant-btn-inverted:active {
  background: #303030 !important;
  color: #fff !important;
}

.ant-btn-inverted::after {
  background: #303030 !important;
  color: #fff !important;
}

.ant-btn-inverted:focus {
  background: #303030 !important;
  color: #fff !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00ba34 !important;
}

.ant-input:hover {
  border-color: #00ba34 !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #00ba34 !important;
}

.ant-input-affix-wrapper:focus {
  border-color: #00ba34 !important;
}

.ant-input:focus {
  border-color: #00ba34 !important;
}

.center-text {
  text-align: center;
}

@media screen and (max-width: 1224px) {
  .header-section {
    display: grid;
    margin-bottom: 60px;
    row-gap: 8px;
  }
}

@media screen and (min-width: 1224px) {
  .header-section {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    margin-bottom: 60px;
    column-gap: 8px;
  }
}

.header-section-title {
  line-height: 25px;
  font-family: Montserrat;
  color: #9f9f9f;
}

.header-section-input {
  background: #ededed !important;
  border-radius: 29px !important;
}

.header-section-input .ant-input {
  background: #ededed !important;
}

.header-section-input .ant-select-selector {
  background: #ededed !important;
  border-radius: 29px !important;
}

.header-section-input .ant-select-clear {
  background: #ededed !important;
}

.ant-tabs-tab-btn {
  color: #999999;
}

.ant-tabs-tab-active * {
  color: #00ba34 !important;
}

.ant-tabs-nav {
  color: #00ba34 !important;
}

.ant-tabs-ink-bar {
  background: #00ba34 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00ba34 !important;
}

.ant-steps-item-finish {
  color: #00ba34 !important;
}

.ant-input-affix-wrapper:focus {
  box-shadow: unset !important;
}

.login-page {
  text-align: center;
  height: 100% !important;
}
.login-control-container {
  background-color: white;
}
.logo-container {
  background-image: url("../../assets/login_background.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 100% !important;
  padding: 0px 20px;
}

.logo-container-text {
  color: white;
  font-size: 64px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  line-height: 70px;
  padding-top: 10%;
}

.logo-container-text-small {
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.login-info-container-style {
  background: #ffffff;
  padding: 0px 50px;
  max-width: 700px;
}

.login-title-style {
  font-size: 50px;
  font-weight: 500;
}

.login-description-style {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 50px;
}

.login-email-style {
  padding: 20px 30px !important;
  margin-bottom: 20px !important;
  border-radius: 5px !important;
  background-color: #f4f4f4 !important;
}

.login-password-style {
  padding: 20px 30px !important;
  border-radius: 5px !important;
  background-color: #f4f4f4 !important;
}

.login-submit-style {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  width: 100% !important;
  padding: 20px 30px !important;
  background-color: #303030;
  color: #eeeeee;
}

.login-signup-style {
  margin-top: 50px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.login-social-icon-style {
  padding: 0px 20px;
  cursor: pointer;
}

.splash-logo {
  margin-top: 18em;
}

.splash {
  display: flex;
  flex-direction: column;
}

.login-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.mobile-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8em;
  gap: 16px;
}

@media screen and (max-width: 1224px) {
  .mobile-login-detail {
    background: #f9f9f9;
    border-radius: 8px;
    width: 21rem !important;
    height: 3rem !important;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1224px) {
  .mobile-login-detail {
    background: #f9f9f9;
    border-radius: 8px;
    width: 100% !important;
    height: 3rem !important;
    margin-bottom: 20px;
  }
}

.mobile-login-button {
  background-color: #303030;
  color: #eeeeee;
  border-radius: 8px;
  width: 21rem;
  height: 3rem;
}

.mobile-social-login-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
}

.mobile-login-social-icon-style {
  cursor: pointer;
}

#recaptcha{
  margin-top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.voting-detail-container {
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgb(253, 230, 230);
}

.voting-detail-info-container {
    display: grid;
    grid-template-rows: auto auto auto auto;
    background-color: #F4F4F4;
    border-radius: 16px !important;
    padding: 10px 20px;
    padding-top: 40px;
}

.voting-detail-info-title {
    font-size: 20px;
}

.voting-detail-select-container {
    text-align: center;
    font-size: 20px;
    color: white;
    font-weight: 500;
    padding: 10px 0px;
}

.voting-detail-status-image-container {
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.vote-status-image {
    width: 80px;
    height: 80px;
    border: 10px solid white;
    border-radius: 50%;
}

.vote-status-house {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.ant-statistic-content-value {
    color: white !important;
}

.voting-detail-active-one-vote-info-status {
    padding: 15px 30px;
}
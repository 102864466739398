.one-type-vote-container {
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgb(253, 230, 230);
  margin-bottom: 40px;
  margin-left: 5%;
  margin-right: 5%;
  cursor: pointer;
  width: 22em;
}

.one-type-vote-container:hover {
  box-shadow: 0px 5px 15px rgb(221, 163, 255);
}

.one-type-vote-info-container {
  background-color: #f4f4f4;
  border-radius: 16px !important;
  padding: 10px 20px;
  padding-top: 40px;
}

.one-type-vote-info-title {
  font-size: 20px;
}

.one-type-vote-select-container {
  text-align: center;
  font-size: 20px;
  color: white;
  font-weight: 500;
  padding: 10px 0px;
}

.one-type-vote-status-image-container {
  position: absolute;
  top: 0;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vote-status-image {
  width: 80px;
  height: 80px;
  border: 10px solid white;
  border-radius: 50%;
}

.vote-status-house {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.ant-statistic-content-value {
  color: white !important;
}
